import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { AspectImageStyles } from './AspectImage.styled.js';

const orientation = {
  square: 'square',
  portrait: 'portrait',
  landscape: 'landscape',
};

const AspectImage = ({
  src,
  srcset,
  imgComponent,
  alt,
  aspectRatio = 1,
  className = '',
  imageOrientation,
  containerOrientation,
  behavior = 'restrict-orientation',
  ...props
}) => {
  const [imgOrientation, setImgOrientation] = useState(imageOrientation);
  const [imgContainerOrientation, setImgContainerOrientation] =
    useState(containerOrientation);

  const imageRef = useRef();
  const containerRef = useRef();

  const getAspectRatio = useCallback(() => {
    return aspectRatio.width / aspectRatio.height || aspectRatio;
  }, [aspectRatio]);

  const detectOrientation = useCallback(() => {
    if (imageRef.current && !imageOrientation) {
      if (imageRef.current.naturalWidth === imageRef.current.naturalHeight) {
        setImgOrientation(orientation.square);
      } else if (containerOrientation == orientation.landscape) {
        if (
          behavior == 'fill-container' &&
          getAspectRatio() >
            imageRef.current.naturalWidth / imageRef.current.naturalHeight
        ) {
          setImgOrientation(orientation.portrait);
        } else {
          setImgOrientation(orientation.landscape);
        }
      } else if (containerOrientation == orientation.portrait) {
        if (
          behavior == 'fill-container' &&
          getAspectRatio() <
            imageRef.current.naturalWidth / imageRef.current.naturalHeight
        ) {
          setImgOrientation(orientation.landscape);
        } else {
          setImgOrientation(orientation.portrait);
        }
      } else {
        if (
          getAspectRatio() <
          imageRef.current.naturalWidth / imageRef.current.naturalHeight
        ) {
          setImgOrientation(orientation.landscape);
        } else {
          setImgOrientation(orientation.portrait);
        }
      }
    }
  }, [behavior, containerOrientation, getAspectRatio, imageOrientation]);

  if (src && srcset) {
    console.warn(
      'src and srcset have been defined, these are incompatible, please choose one or the other'
    );
  }

  useEffect(() => {
    if (src && !srcset) {
      if (imageRef.current && !imageRef.current.naturalWidth) {
        imageRef.current.onload = detectOrientation;
      } else {
        detectOrientation();
      }
    }

    if (containerRef.current) {
      if (!containerOrientation) {
        if (
          containerRef.current.clientWidth === containerRef.current.clientHeight
        ) {
          setImgContainerOrientation(orientation.square);
        } else if (
          containerRef.current.clientWidth < containerRef.current.clientWidth
        ) {
          setImgContainerOrientation(orientation.portrait);
        } else {
          setImgContainerOrientation(orientation.landscape);
        }
      }
    }
  }, [containerOrientation, detectOrientation, src, srcset]);

  const singleImage = () => {
    return imgComponent ? (
      React.createElement(imgComponent, {
        src: src,
        alt: alt,
        className: behavior + ' ' + imgOrientation,
        fill: true,
        ...props,
      })
    ) : (
      <img
        alt={alt}
        className={behavior + ' ' + imgOrientation}
        ref={imageRef}
        src={src}
        {...props}
      />
    );
  };

  const imageSet = () => {
    return (
      <picture>
        {srcset
          .sort((a, b) => (b.mediaWidth || 1) - (a.mediaWidth || 1))
          .map((set, i) => {
            return (
              <source
                key={i}
                srcSet={set.src}
                media={`(min-width: ${set.mediaWidth || 1}px)`}
              />
            );
          })}
        <img
          className={`${behavior} ${imgOrientation}`}
          ref={imageRef}
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z8BQDwAEhQGAhKmMIQAAAABJRU5ErkJggg=="
          alt={alt}
        />
      </picture>
    );
  };

  return (
    <AspectImageStyles
      srcset={srcset}
      ref={containerRef}
      className={`aspect-image ${imgContainerOrientation} ${className}`}
    >
      <div
        style={
          !srcset
            ? {
                paddingTop: (1 / getAspectRatio()) * 100 + '%',
              }
            : null
        }
        className="aspect-holder"
      >
        Aspect
      </div>
      {srcset ? imageSet() : singleImage()}
    </AspectImageStyles>
  );
};

AspectImage.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  src: PropTypes.string,
  srcset: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      mediaWidth: PropTypes.string,
      aspectRatio: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.shape({
          width: PropTypes.number,
          height: PropTypes.number,
        }),
      ]),
    })
  ),
  imgComponent: PropTypes.object,
  aspectRatio: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({ width: PropTypes.number, height: PropTypes.number }),
  ]),
  imageOrientation: PropTypes.oneOf([
    undefined,
    'landscape',
    'portrait',
    'square',
  ]),
  containerOrientation: PropTypes.oneOf([
    undefined,
    'landscape',
    'portrait',
    'square',
  ]),
  behavior: PropTypes.oneOf(['fill-container', 'restrict-container']),
};

export default AspectImage;
