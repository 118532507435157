import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors';
import { lg_mobile_breakpoint } from 'pubweb-smokey/dist/components/GridSystem/_vars_widths';

export const HomeCardAspectStyles = styled.div`
  a {
    text-decoration: none;
  }
  .card-image-area {
    border-radius: 6px;
    left: 0;
    overflow: hidden;
    position: relative;
    margin-bottom: 13px;
    width: 100%;

    &.card-slideshow {
      margin-bottom: 5px;
    }

    /* to make prev/next arrows appear when hover over or tab into card */
    :hover,
    :focus-within {
      .carousel-slider .control-prev,
      .carousel-slider .control-next {
        opacity: 1 !important;
        transform: translateX(0px) !important;
      }
    }
    .carousel-slider {
      button {
        :focus {
          outline: 2px solid black;
        }
      }
    }

    .card-image-single img {
      border-radius: 6px;
    }

    .sticker-container {
      position: absolute;
      top: 10px;
      left: 10px;
      display: flex;
      flex-wrap: wrap;
      z-index: 2;

      .sticker {
        background-color: ${Colors.secondary.grey6.standard};
        border-radius: 30px;
        color: ${Colors.primary.black.standard};
        padding: 4px 7px;
        margin-right: 7px;
        margin-bottom: 5px;

        .in-stock-dot {
          background-color: ${Colors.secondary.kiddiePool.standard};
        }
        .sticker-dot {
          width: 6px;
          height: 6px;
          border-radius: 50%;
          margin: 4px 3px 4px 2px;
          float: left;
        }
      }
    }

    .favoriting-heart-container-card {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }

  .card-info-area {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .card-heading,
    .card-home-specs {
      margin: 0;
    }
    .price {
      color: ${Colors.primary.black.standard};
      text-align: right;
      margin-top: -3px;

      .label {
        margin: 0;
        padding: 0;
      }
      .intro {
        margin-top: -3px;
      }
    }
    .price-contact {
      text-align: right;
      width: 118px;
    }

    .phone-icon {
      flex-shrink: 0;
      vertical-align: bottom;

      path {
        fill: ${Colors.primary.claytonBlue.standard};
      }
    }

    .contact-link {
      display: flex;
      font-size: 14px;
      font-weight: 600;
      gap: 3px;
      line-height: 16px;
      letter-spacing: 1px;
      text-transform: uppercase;

      &:link {
        text-decoration: none;
      }
      &:link,
      &:visited {
        color: ${Colors.primary.claytonBlue.standard};
        font-family: 'source-sans-pro', sans-serif;
      }
    }
  }

  .carousel-root {
    height: 100%;
    .carousel {
      border-radius: 6px;

      .control-arrow {
        padding: 0 !important;
        opacity: 1;

        /* to display the circle with a chevron for prev/next arrows */
        width: 30px;
        height: 30px;
        border-radius: 15px;
        background-color: #fff !important;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);

        /* this set content to none is needed to get rid of the 'before' that pushes the arrow images down */
        :before {
          content: none;
        }
        border: none !important;
        cursor: pointer !important;
        position: absolute !important;
        top: calc(50% - 15px) !important;
        svg {
          position: absolute;
          top: 9px;
          height: 20px;
          width: 20px;
        }
      }
      .control-prev {
        left: 10px !important;
        opacity: 0 !important;
        transition: 0.2s !important;
        transform: translateX(-10px);
        svg {
          left: 10px;
        }
      }
      .control-next {
        right: 10px !important;
        opacity: 0 !important;
        transition: 0.2s !important;
        transform: translateX(10px);
        svg {
          left: 12px;
        }
      }

      .slider-wrapper {
        .slide {
          background-color: ${Colors.secondary.grey6.standard};
          border-radius: 6px;
        }
      }

      .thumbs-wrapper {
        margin: 10px 0;

        .thumb {
          border: none;
          max-height: 80px;
          padding: 0;

          &.selected {
            border: solid 2px black;
          }
        }
      }
    }
  }

  .info-circle {
    margin-left: 3px;
    width: 14px;
    height: 14px;
    vertical-align: middle;
  }

  .price-tooltip {
    position: relative;
    cursor: pointer;
  }

  .tippy-content {
    padding: 20px;
    .close-btn {
      position: absolute;
      top: 4px;
      right: 4px;
      cursor: pointer;
      width: 20px;
      height: 20px;
      filter: invert(1);
      pointer-events: auto;
    }
  }

  [data-tippy-root] {
    max-width: 90%;
  }

  [data-theme='clayton'] {
    background-color: ${Colors.primary.claytonBlue.standard};
    p {
      color: ${Colors.primary.white.standard};
      font-size: 14px;
      line-height: 22px;
    }
  }

  /* [data-theme='clayton'][data-animatefill] {
      background-color: transparent;
    } */

  [data-theme='clayton'] .tippy-backdrop {
    background-color: ${Colors.primary.claytonBlue.standard};
  }

  .tippy-arrow {
    color: ${Colors.primary.claytonBlue.standard};
  }

  @media only screen and (min-width: ${lg_mobile_breakpoint}px) {
    .card-info-area {
      .price-contact {
        width: 109px;
      }
    }
  }
`;
