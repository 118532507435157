import styled from 'styled-components';

export const AspectImageStyles = styled.div`
  ${(props) => {
    if (props.srcset) {
      return props.srcset
        .sort((a, b) => (a.mediaWidth || 1) - (b.mediaWidth || 1))
        .map((set) => {
          if (set.mediaWidth) {
            return `@media screen and (min-width: ${set.mediaWidth}px) {
                        .aspect-holder {
                            padding-top: ${
                              (1 /
                                (set.aspectRatio.width /
                                  set.aspectRatio.height) || set.aspectRatio) *
                              100
                            }%;
                        }
                    }`;
          }

          return `.aspect-holder {
                        padding-top: ${
                          (1 /
                            (set.aspectRatio.width / set.aspectRatio.height) ||
                            set.aspectRatio) * 100
                        }%;
                    }`;
        })
        .join(' ');
    }
  }}

  display: flex;
  height: 100%;
  position: relative;
  overflow: hidden;
  width: 100%;

  .aspect-holder {
    font-size: 0;
    visibility: hidden;
  }

  img {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &.square {
    img.square {
      height: 100%;
      width: 100%;
    }

    img.restrict-container.landscape,
    img.fill-container.portrait {
      width: 100%;
    }

    img.restrict-container.portrait,
    img.fill-container.landscape {
      height: 100%;
    }
  }

  &.landscape {
    height: 100%;
    width: 100%;

    img.fill-container.landscape {
      height: 100%;
    }

    img.restrict-container.square,
    img.restrict-container.portrait {
      max-height: 100%;
    }

    img.fill-container.square,
    img.fill-container.portrait {
      width: 100%;
    }

    img.restrict-container.landscape {
      max-width: 100%;
    }
  }

  &.portrait {
    height: 100%;
    width: 100%;

    img.fill-container.portrait {
      width: 100%;
    }

    img.restrict-container.square,
    img.restrict-container.landscape {
      max-width: 100%;
    }

    img.fill-container.square,
    img.fill-container.landscape {
      height: 100%;
    }

    img.restrict-container.portrait {
      max-height: 100%;
    }
  }
`;
